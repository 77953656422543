import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
const configDev = {
  apiKey: 'AIzaSyDMBd_zQuH7oztx_uQy9QZ-e6Q-DVMdi-o',
  authDomain: 'universily-v2-dev.firebaseapp.com',
  projectId: 'universily-v2-dev',
  storageBucket: 'universily-v2-dev.appspot.com',
  messagingSenderId: '356278622889',
  appId: '1:356278622889:web:85baee8395c557a74127b8',
  measurementId: 'G-BJTWM5LQGH',
};
const configProd = {
  apiKey: 'AIzaSyCGAESGcYqL13Ib3Uhyca2u0ERWBdzlyCs',
  authDomain: 'universily-v2-prod.firebaseapp.com',
  projectId: 'universily-v2-prod',
  storageBucket: 'universily-v2-prod.appspot.com',
  messagingSenderId: '539823441538',
  appId: '1:539823441538:web:47e32b7c88b195e9aed69b',
  measurementId: 'G-P0PCC09TT2',
};
// Allow developer to switch between Dev and Prod DB using code.
const dev = true;
const config = dev ? configDev : configProd;
const firebase = initializeApp(config);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);
const performance = getPerformance(firebase);
const db = getFirestore(firebase);
export { firebase, analytics, auth, storage, performance, db };
