import { useEffect, useState } from 'react';
import {
  addStoryItemTypes,
  CIO_SHARE_ITEM_TYPES,
  firebaseDataType,
} from '../constants/other';
import {
  ACTIVITIES,
  ACTIVITY_TYPES,
  COMMON_ESSAYS,
  APPLICATION_TEMPLATES,
  COLLEGES_LIST,
  COURSE,
  ESSAY,
  HONORS,
  HONOR_TYPES,
  NICHE,
  PROFILE,
  PROFILE_LOGIN,
  REFERRAL,
  REVIEWER,
  TEST_SCORES,
  TEST_SUBJECTS,
  TEST_TARGETS,
  TEST_TYPES,
  UNIVERSILY_TEMPLATES,
  APPLICATIONS,
  GPA_SCORES,
  TEAMS,
} from '../firebase/constants';
import {
  getProfileByUserId,
  getProfileDataByEmail,
} from '../firebase/services/user';
import { useStoryItemListener } from '../hooks';
import useMyTeamStore from '../stores/MyTeamStore';
import useProfileStore from '../stores/ProfileStore';
import ChatWindowContainer from './ChatWindow.container';
import TeamMemberContainer from './TeamMember.container';
import { userTypeKeywords } from '../constants/keywords';
import MyAccountContainer from './MyAccount.container';
import useInviteAFriendStore from '../stores/InviteAFriendStore';
import { ReferAFriendContainer } from './ReferAFriend.container';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import useCourseStore from '../stores/CourseStore';
import useActivityStore from '../stores/ActivityStore';
import useNicheStore from '../stores/NicheStore';
import useHonorStore from '../stores/HonorStore';
import useStudentStore from '../stores/StudentStore';
import useTestStore from '../stores/TestStore';
import { getApplicationCompletionValue, sortData } from '../utils/helper';
import useTemplateStore from '../stores/TemplateStore';
import { MyEssayContainer } from './MyEssay.container';
import useEssayStore from '../stores/EssayStore';
import AdminContainer from './Admin.container';
import useAdminStore from '../stores/AdminStore';
import useApplicationStore from '../stores/ApplicationStore';
import { MyApplicationContainer } from './MyApplication.container';
import useMyStudentStore from '../stores/MyStudentStore';
import MyStudentContainer from './MyStudent.container';
import { useGPAStore } from '../stores/GPAStore';
import { fetchDocuments } from '../firebase/services/getServices';
import {
  GlobalSnackbar,
  openSnackbar,
} from '../components/shared/GlobalSnackbar/GlobalSnackbar';
import useTeamStore from '../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import { useParams } from 'react-router-dom';
import { ShareUtility } from '../components/myHub/ShareUtility';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../utils/utlityTypes';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';
import useMyHubStore from '../stores/MyHubStore';

const AppContainer = () => {
  const courseStore = useCourseStore();
  const activityStore = useActivityStore();
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const myTeamStore = useMyTeamStore();
  const myStudentStore = useMyStudentStore();
  const testStore = useTestStore();
  const gpaStore = useGPAStore();
  const profileStore = useProfileStore();
  const studentStore = useStudentStore();
  const templateStore = useTemplateStore();
  const essayStore = useEssayStore();
  const applicationStore = useApplicationStore();
  const inviteStore = useInviteAFriendStore();
  const [fetchId, setFetchId] = useState('');
  const [owner, setOwner] = useState('');
  const adminStore = useAdminStore();
  const { teamID } = useParams();
  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
      utilityName: state.utilityName,
    }))
  );

  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      setTeams: state.setTeams,
      selectedTeam: state.selectedTeam,
      setSelectedTeam: state.setSelectedTeam,
    }))
  );

  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
    profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;

  useEffect(() => {
    const id = isParent
      ? teamStore?.selectedTeam?.uid
      : profileStore.profile?.uid;
    const email = isParent
      ? teamStore?.selectedTeam?.studentEmail
      : profileStore.profile?.email;
    //setFetchId(id);
    setOwner(email);
    // eslint-disable-next-line
  }, [teamStore.selectedTeam?.studentEmail, profileStore.profile?.uid]);

  // Check for premium access and can create new utilities and set it to profile store
  useEffect(() => {
    const currentProfile = isParent
      ? teamStore?.selectedTeam
      : profileStore.profile;
    profileStore?.setHasPremiumAccess(currentProfile);
    profileStore?.setCanCreateNewUtilities(currentProfile);
  }, [teamStore.selectedTeam?.studentEmail, profileStore.profile?.uid]);
  const { data: nichesData } = useStoryItemListener(
    NICHE,
    firebaseDataType.METADATA,
    [
      {
        property: 'owner',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: owner,
      },
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ],
    fetchId
  );

  // eslint-disable-next-line no-unused-vars
  const { data: commonEssayData, isFetching: isFetchingCommonEssay } =
    useStoryItemListener(
      COMMON_ESSAYS,
      firebaseDataType.METADATA,
      [
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      fetchId
    );

  const { data: googleTemplateData } = useStoryItemListener(
    UNIVERSILY_TEMPLATES,
    firebaseDataType.METADATA
  );

  // Filter items only shared with current logged in collaborator
  const checkReviewer = (dataList) => {
    const dataItems = dataList?.filter((item) =>
      item?.sharedWith?.includes(profileStore.profile?.uid)
    );
    return dataItems;
  };

  const { data: activitiesData, isFetching: isFetchingActivities } =
    useStoryItemListener(
      ACTIVITIES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );
  const { data: honorsData, isFetching: isFetchingHonors } =
    useStoryItemListener(
      HONORS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      fetchId
    );

  const { data: coursesData, isFetching } = useStoryItemListener(
    COURSE,
    firebaseDataType.METADATA,
    [
      {
        property: 'owner',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: owner,
      },
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ],
    fetchId
  );

  const { data: testTypeData, isFetching: isFetchingTestType } =
    useStoryItemListener(
      TEST_TYPES,
      firebaseDataType.METADATA,
      [
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      fetchId
    );

  const { data: essaysData, isFetching: isFetchingEssays } =
    useStoryItemListener(
      ESSAY,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      fetchId
    );

  const { data: testScoresData, isFetching: isFetchingTestScores } =
    useStoryItemListener(
      TEST_SCORES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: gpaScores, isFetching: isFetchingGPAScores } =
    useStoryItemListener(
      GPA_SCORES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
      ],
      fetchId
    );

  const { data: testTargetData, isFetching: isFetchingTestTarget } =
    useStoryItemListener(
      TEST_TARGETS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: activityTypesData } = useStoryItemListener(
    ACTIVITY_TYPES,
    firebaseDataType.METADATA
  );

  const { data: honorTypesData } = useStoryItemListener(
    HONOR_TYPES,
    firebaseDataType.METADATA
  );

  const addUtilityType = (data, type) => {
    return data?.map((item) => {
      return { ...item, utilityType: type };
    });
  };

  const addApplicationProgressValue = (utilData) => {
    if (utilData?.length > 0) {
      return utilData?.map((item) => {
        if (item?.markedAsSubmitted) {
          return {
            ...item,
            applicationProgress: 100,
          };
        } else {
          return {
            ...item,
            applicationProgress: getApplicationCompletionValue(item),
          };
        }
      });
    }
  };

  useEffect(() => {
    const coursesFilteredData = isParent
      ? checkReviewer(coursesData)
      : coursesData;
    const utilityData = addUtilityType(
      coursesFilteredData,
      addStoryItemTypes.COURSE
    );
    const sortedData = sortData(utilityData);
    courseStore.setCourses(sortedData || []);
    courseStore.setIsFetching(isFetching || false);
    // eslint-disable-next-line
  }, [coursesData, isFetching]);

  useEffect(() => {
    templateStore.setTemplateData(googleTemplateData);
    // eslint-disable-next-line
  }, [googleTemplateData]);

  useEffect(() => {
    nicheStore.setNiches(nichesData || []);
    // eslint-disable-next-line
  }, [nichesData]);

  useEffect(() => {
    testStore.setTestTypes(testTypeData || []);
    // eslint-disable-next-line
  }, [testTypeData, isFetchingTestType]);

  useEffect(() => {
    const utilityData = addUtilityType(
      testScoresData,
      addStoryItemTypes.TEST_SCORE
    );
    const sortedData = sortData(utilityData);
    testStore.setTestScores(sortedData);
    // eslint-disable-next-line
  }, [testScoresData, isFetchingTestScores]);

  useEffect(() => {
    gpaStore.setGpaScores(gpaScores || []);
    gpaStore.setIsFetchingScores(isFetchingGPAScores);
    // eslint-disable-next-line
  }, [gpaScores, isFetchingGPAScores]);

  useEffect(() => {
    testStore.setTestTargets(testTargetData || []);
    // eslint-disable-next-line
  }, [testTargetData, isFetchingTestTarget]);

  useEffect(() => {
    essayStore.setCommonEssays(commonEssayData);
    //eslint-disable-next-line
  }, [commonEssayData]);

  const TEAMS_QUERY = [
    {
      property: 'id',
      operator: firebaseQueryOperators.IN,
      value: profileStore.profile?.teams?.map((team) => team?.teamId),
    },
  ];

  const { data: teamData, isFetching: isFetchingTeamData } =
    useStoryItemListener(TEAMS, firebaseDataType.METADATA, TEAMS_QUERY);

  const { data: profileData, isFetching: isFetchingProfileData } =
    useStoryItemListener(PROFILE);

  const { data: selectedStudentData } = useStoryItemListener(
    addStoryItemTypes.PROFILE,
    firebaseDataType.METADATA,
    [
      {
        property: 'email',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: studentStore.selectedStudent?.email,
      },
    ]
  );

  const { data: profileLoginData, isFetching: isFetchingProfileLoginData } =
    useStoryItemListener(PROFILE_LOGIN);

  useEffect(() => {
    studentStore?.setSelectedStudent(
      selectedStudentData && selectedStudentData[0]
    );
  }, [selectedStudentData]);

  useEffect(() => {
    profileStore.setProfile(profileData && profileData[0]);
    profileStore.setIsSubscriptionActive(
      profileData &&
        profileData[0]?.isSubscriptionActive &&
        profileData[0]?.isSubscriptionPurchased
    );
    // eslint-disable-next-line
  }, [profileData, isFetchingProfileData]);

  useEffect(() => {
    profileStore.setProfileLogin(profileLoginData && profileLoginData[0]);
    // eslint-disable-next-line
  }, [profileLoginData, isFetchingProfileLoginData]);

  useEffect(() => {
    const essayList = isParent ? checkReviewer(essaysData) : essaysData;
    const utilData = addUtilityType(essayList, addStoryItemTypes.ESSAY);
    essayStore.setEssays(sortData(utilData) || []);
    essayStore.setIsFetchingEssays(isFetchingEssays);
    //eslint-disable-next-line
  }, [essaysData, isFetchingEssays]);

  const { data: referralData, isFetching: isFetchingInviteAFriendData } =
    useStoryItemListener(REFERRAL);

  const getProfileDataForReferral = async () => {
    const teamMemberProfileData = referralData?.map((referral) => {
      return {
        ...referral,
        isRegistered: referral?.isRegistered ? referral?.isRegistered : false,
      };
    });
    inviteStore.setInvitedMembers(teamMemberProfileData || []);
    inviteStore.setIsFetchingInviteAFriendData(
      isFetchingInviteAFriendData || false
    );
  };

  const getProfileDataForReviewer = async () => {
    // Fetch logged in user team data from team collection
    const loggedInUserTeams = profileStore?.profile?.teams;
    if (loggedInUserTeams?.length > 0) {
      const loggedInUserTeamData = await fetchDocuments(
        TEAMS,
        loggedInUserTeams,
        'teamId',
        'id'
      );
      //Fetch team members profile data from profile collection
      const teamMembersProfileData = await fetchDocuments(
        PROFILE,
        loggedInUserTeamData[0]?.teamMembers,
        'uid',
        'uid'
      );

      // Map team members data with profile data to get complete team members data (photoURL,name,etc.)
      const teamMembers = loggedInUserTeamData[0]?.teamMembers.map(
        (teamMember) => {
          const profileData = teamMembersProfileData.find(
            (profile) => profile?.uid === teamMember.uid
          );
          if (profileData) {
            return {
              ...teamMember,
              profileId: profileData?.id,
              profileUserId: profileData?.uid,
              photoURL: profileData?.photoURL,
              profileDate: profileData?.profileDate,
              name: profileData?.profileName,
              firstName: profileData?.firstName,
              lastName: profileData?.lastName,
            };
          } else {
            return {
              ...teamMember,
            };
          }
        }
      );

      // Set team members data to myTeamStore
      myTeamStore.setMyTeam(teamMembers || []);
      myTeamStore.setIsFetchingTeamData(isFetchingTeamData || false);
    }
  };

  const getStudentsDataForReviewer = async () => {
    const teamIDs = profileStore.profile.teams;
    if (teamIDs) {
      const teamsData = await fetchDocuments(TEAMS, teamIDs, 'teamId', 'id');

      myStudentStore.setMyStudents(teamsData || []);
      myStudentStore.setIsFetching(isFetchingTeamData || false);
    }
  };
  const getTeamsData = async () => {
    const teamIDs = profileStore.profile?.teams;
    if (teamIDs) {
      const teamsData = await fetchDocuments(TEAMS, teamIDs, 'teamId', 'id');
      const teamsDataWithStudentLicense = teamsData.map(async (team) => {
        const studentEmail = team?.studentEmail;
        const studentProfile = await getProfileDataByEmail(studentEmail);
        return {
          ...team,
          licenseData: studentProfile?.licenseData,
          studentFirstName: studentProfile?.firstName,
          studentLastName: studentProfile?.lastName,
          studentPhotoURL: studentProfile?.photoURL,
          studentUID: studentProfile?.uid,
          studentGrade: studentProfile?.grade,
          studentSchoolName: studentProfile?.schoolName,
          studentAddress: studentProfile?.address,
          studentProfileType: studentProfile?.profileType,
        };
      });
      const teamsDataWithStudentLicenseResolved = await Promise.all(
        teamsDataWithStudentLicense
      );

      teamStore.setTeams(teamsDataWithStudentLicenseResolved || []);
      //teamStore.setIsFetchingTeamData(isFetchingTeamData || false);
      if (!isParent) {
        const team = teamsDataWithStudentLicense.find(
          (team) => team?.studentEmail === profileStore.profile?.email
        );
        const collabProfiles = await Promise.all(
          team?.teamMembers?.map(async (member) => {
            let memberDetails = { ...member };
            if (member?.uid) {
              const memberProfile = await getProfileByUserId(member?.uid);

              if (memberProfile.length > 0) {
                const { photoURL, firstName, lastName } = memberProfile[0];
                memberDetails = {
                  ...memberDetails,
                  photoURL,
                  firstName,
                  lastName,
                };
              }
            }
            return memberDetails;
          })
        );

        teamStore?.setSelectedTeam({
          ...team,
          teamMembers: collabProfiles,
        });
      } else if (teamID) {
        if (
          teamsDataWithStudentLicenseResolved?.find(
            (team) => team.id === teamID
          )?.id
        ) {
          const teamDetails = teamsDataWithStudentLicenseResolved?.find(
            (team) => team.id === teamID
          );

          const collabProfiles = await Promise.all(
            teamDetails?.teamMembers?.map(async (member) => {
              let memberDetails = { ...member };
              if (member?.uid) {
                const memberProfile = await getProfileByUserId(member?.uid);

                const { photoURL, firstName, lastName } = memberProfile[0];
                memberDetails = {
                  ...memberDetails,
                  photoURL,
                  firstName,
                  lastName,
                };
              }
              return memberDetails;
            })
          );

          teamStore?.setSelectedTeam({
            ...teamDetails,
            teamMembers: collabProfiles,
          });
        }
      }
    }
  };

  const { data: testSubjectSAT, isFetching: isFetchingTestSubjectSAT } =
    useStoryItemListener(
      testTypeData
        ? `${TEST_TYPES}/${testTypeData[0]?.id}/${TEST_SUBJECTS}`
        : '',
      firebaseDataType.METADATA
    );

  const { data: testSubjectACT, isFetching: isFetchingTestSubjectACT } =
    useStoryItemListener(
      testTypeData
        ? `${TEST_TYPES}/${testTypeData[1]?.id}/${TEST_SUBJECTS}`
        : '',
      firebaseDataType.METADATA
    );

  useEffect(() => {
    const SATSubjectsSorted = testSubjectSAT?.sort(
      (sub1, sub2) => sub1.order - sub2.order
    );
    testStore.setTestSubjectsSAT(SATSubjectsSorted);
    // eslint-disable-next-line
  }, [testSubjectSAT, isFetchingTestSubjectSAT]);

  useEffect(() => {
    const ACTSubjectsSorted = testSubjectACT?.sort(
      (sub1, sub2) => sub1.order - sub2.order
    );
    testStore.setTestSubjectsACT(ACTSubjectsSorted);
    // eslint-disable-next-line
  }, [testSubjectACT, isFetchingTestSubjectACT]);

  useEffect(() => {
    getProfileDataForReferral();
    // eslint-disable-next-line
  }, [referralData, isFetchingInviteAFriendData]);

  useEffect(() => {
    getProfileDataForReviewer();
    // eslint-disable-next-line
  }, [teamData, isFetchingTeamData]);

  useEffect(() => {
    getStudentsDataForReviewer();
    // eslint-disable-next-line
  }, [teamData, isFetchingTeamData]);
  useEffect(() => {
    if (!isFetchingTeamData && teamData) {
      getTeamsData();
    }
  }, [teamData, isFetchingTeamData]);

  useEffect(() => {
    const activitiesFilteredData = isParent
      ? checkReviewer(activitiesData)
      : activitiesData;
    const utilityData = addUtilityType(
      activitiesFilteredData,
      addStoryItemTypes.ACTIVITY
    );
    const activityData = sortData(utilityData);
    activityStore.setActivities(activityData || []);
    activityStore.setDeleteActivityId(null);
    activityStore.setIsFetchingActivities(isFetchingActivities);
    // eslint-disable-next-line
  }, [activitiesData, isFetchingActivities]);

  useEffect(() => {
    activityStore.setActivityTypes(activityTypesData || []);
    // eslint-disable-next-line
  }, [activityTypesData]);

  useEffect(() => {
    const honorsFilteredData = isParent
      ? checkReviewer(honorsData)
      : honorsData;
    const utilityData = addUtilityType(
      honorsFilteredData,
      addStoryItemTypes.HONOR
    );
    const honorData = sortData(utilityData);
    honorStore.setHonors(honorData || []);
    honorStore.setDeleteHonorId(null);
    honorStore.setIsFetchingHonors(isFetchingHonors);
    // eslint-disable-next-line
  }, [honorsData, isFetchingHonors]);

  useEffect(() => {
    honorStore.setHonorTypes(honorTypesData || []);
    // eslint-disable-next-line
  }, [honorTypesData]);

  const { data: applicationDetail } = useStoryItemListener(
    APPLICATION_TEMPLATES,
    firebaseDataType.METADATA,
    [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]
  );

  const { data: collegeList } = useStoryItemListener(
    COLLEGES_LIST,
    firebaseDataType.METADATA
  );

  useEffect(() => {
    adminStore.setApplicationRequirements(applicationDetail);
    // eslint-disable-next-line
  }, [applicationDetail]);

  useEffect(() => {
    adminStore.setCollegeDetails(collegeList);
    // eslint-disable-next-line
  }, [collegeList]);

  useEffect(() => {
    let data = [];
    const collegeListData = sortData(collegeList);

    collegeListData?.forEach((college) => {
      let i = 0;
      applicationDetail?.forEach((item) => {
        if (
          (item?.collegeId === college?.id ||
            item?.collegeData?.id === college?.id) &&
          data.every((value) => value?.collegeData?.id !== college?.id)
        ) {
          i = 1;
          data.push({
            ...item,
            collegeApplicationPortal: college?.collegeApplicationPortal || '',
            collegeId: item?.collegeId || college?.id,
            collegeData: {
              id: college?.id,
              collegeApplicationPortal: college?.collegeApplicationPortal || '',
              createdAt: college?.createdAt || '',
              updatedAt: college?.updatedAt || '',
              deletedAt: college?.deletedAt || '',
              collegeId: college?.id || '',
              collegeUrl: college?.collegeUrl || '',
              collegeLogoURL: college?.collegeLogoURL || '',
              collegeShortName: college?.collegeShortName || '',
              collegeHiddenName: college?.collegeHiddenName || '',
              collegeName: college?.collegeName,
              deleteDocument: college?.deleteDocument,
            },
          });
        }
      });
      if (i === 0) {
        data.push({
          collegeApplicationPortal: college?.collegeApplicationPortal || '',
          collegeId: college?.id,
          collegeData: {
            id: college?.id,
            collegeApplicationPortal: college?.collegeApplicationPortal || '',
            createdAt: college?.createdAt || '',
            updatedAt: college?.updatedAt || '',
            deletedAt: college?.deletedAt || '',
            collegeId: college?.id || '',
            collegeUrl: college?.collegeUrl || '',
            collegeLogoURL: college?.collegeLogoURL || '',
            collegeShortName: college?.collegeShortName || '',
            collegeHiddenName: college?.collegeHiddenName || '',
            collegeName: college?.collegeName,
            deleteDocument: college?.deleteDocument,
          },
        });
      }
    });
    const sortedData = sortData(data);
    adminStore.setCollegeListData(sortedData);
    // eslint-disable-next-line
  }, [applicationDetail, collegeList]);

  const { data: applicationsData, isFetching: isFetchingApplicationsData } =
    useStoryItemListener(
      APPLICATIONS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  useEffect(() => {
    const filteredApplications = isParent
      ? applicationsData?.filter((data) =>
          data.sharedWith?.includes(profileStore.profile?.uid)
        )
      : applicationsData;
    const applicationAllData = filteredApplications?.map((college) => {
      const template = adminStore.collegeListData?.find(
        (collegeData) => collegeData?.collegeId === college?.collegeId
      );
      return {
        ...template,
        ...college,
        label: template?.collegeData?.collegeName,
        collegeLogoURL: template?.collegeData?.collegeLogoURL,
      };
    });
    const utilData = addUtilityType(
      applicationAllData,
      addStoryItemTypes.APPLICATIONS
    );
    const appDataWithProgress = addApplicationProgressValue(utilData);
    applicationStore.setApplications(sortData(appDataWithProgress));
    //eslint-disable-next-line
  }, [
    applicationsData,
    adminStore.collegeListData,
    isFetchingApplicationsData,
  ]);

  const handleShareUtility = async (teamMemberData) => {
    const payload = {
      teamId: teamStore.selectedTeam?.id,
      itemType: CIO_SHARE_ITEM_TYPES[myHubStore.utilityType].name,
      itemName: myHubStore.utilityName,
      userId: profileStore.profile?.uid,
      senderFirstName: profileStore.profile?.firstName,
      senderLastName: profileStore.profile?.lastName,
      senderEmail: profileStore.profile?.email,
      teamMembers: teamMemberData,
      teamName: teamStore.selectedTeam?.teamName,
      destinationLink:
        CIO_SHARE_ITEM_TYPES[myHubStore.utilityType].destinationLink,
    };

    const result = await axiosPost(ApiRoutes?.SHARE_ITEM, payload);

    if (result.data.status === 'success') {
      openSnackbar('Item shared successfully!');
      myHubStore.setIsShareUtility(false);
      myHubStore.setUtilityId('');
      myHubStore.setUtilityType('');
      myHubStore.setUtilityName('');
    }
    return result;
  };

  const teamDataReviewers = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== profileStore.profile?.email)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
        }))
    : [];

  return (
    <>
      <TeamMemberContainer />
      {profileStore.profile?.uid && (
        <ChatWindowContainer profileUserId={profileStore.profile?.uid} />
      )}
      {profileStore.profile?.uid && (
        <MyAccountContainer profileUserId={profileStore.profile?.uid} />
      )}
      <MyEssayContainer />
      {profileStore.profile?.uid && <ReferAFriendContainer />}
      {profileStore.profile?.uid &&
        [
          userTypeKeywords.FAMILY,
          userTypeKeywords.PRIVATE_COUNSELOR,
          userTypeKeywords.SCHOOL_COUNSELOR,
          userTypeKeywords.TEACHER,
        ].includes(profileStore.profile?.profileType) && <MyStudentContainer />}
      {profileStore.profile?.profileType === userTypeKeywords.ADMINISTRATOR && (
        <AdminContainer />
      )}
      <MyApplicationContainer />
      <GlobalSnackbar />
      {myHubStore.isShareUtility ? (
        <ShareUtility
          open={myHubStore.isShareUtility}
          onClose={() => myHubStore.setIsShareUtility(false)}
          onCancel={() => myHubStore.setIsShareUtility(false)}
          utilityId={myHubStore.utilityId}
          onContinue={(teamMembers) => handleShareUtility(teamMembers)}
          reviewers={filterUniAndTeamUniversily(teamDataReviewers)}
          teamMemberData={filterUniAndTeamUniversily(teamDataReviewers)}
        />
      ) : null}
    </>
  );
};

export default AppContainer;
